<template>
    <ul>
        <template v-if="parkList && parkList.length > 0">
            <ul>
                <li
                    v-for="(park, index) of filteredParkList"
                    :key="`park-${index}`"
                >
                    <router-link
                        :to="{
                            name: 'park',
                            params: { parkId: park.id },
                        }"
                        >{{ park.name }}</router-link
                    >
                </li>
            </ul>
        </template>
    </ul>
</template>

<script lang="ts">
import { JsonObject } from "../types";
import { defineComponent } from "vue";

function sortByName(a: JsonObject, b: JsonObject): number {
    if ((a.name as string) < (b.name as string)) {
        return -1;
    }
    if ((a.name as string) > (b.name as string)) {
        return 1;
    }
    return 0;
}

export default defineComponent({
    name: "ParksView",
    props: {
        search: String,
    },
    data() {
        return {
            groupList: null as null | JsonObject[],
        };
    },
    created() {
        fetch("/parks.json")
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Network response was not ok " + response.statusText);
                }
                return response.json();
            })
            .then((data) => {
                if (data.groups) this.groupList = data.groups;
            })
            .catch((error) => {
                console.error("Error loading JSON:", error);
            });
    },
    computed: {
        filteredParkList(): JsonObject[] {
            let list: JsonObject[] = [];
            if (this.search && this.search.length > 0 && this.search != undefined) {
                list = (this.parkList as JsonObject[]).filter((a) => (a.name as string).toLowerCase().includes(String(this.search).toLowerCase())).sort(sortByName);
            } else {
                list = this.parkList as JsonObject[];
            }
            return list.sort(sortByName);
        },
        parkList(): JsonObject[] {
            let list: JsonObject[] = [];
            if (this.groupList) {
                for (let group of this.groupList) {
                    for (let park of group.parks as JsonObject[]) {
                        list.push(park as JsonObject);
                    }
                }
            }
            return list;
        },
    },
});
</script>

<style lang="scss" scoped>
ul,
li {
    list-style: none;
    margin: 0;
    padding: 0;
    a {
        padding: 0.5rem;
        display: block;
        text-decoration: none;
        color: #333;
        &:hover {
            background-color: #f1f1f1;
        }
    }
}

@media all and (min-width: 760px) {
    input {
        margin-top: 0;
        width: 250px;
    }
}
</style>
