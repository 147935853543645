import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-68ef0d12"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("ul", null, [
    (_ctx.parkList && _ctx.parkList.length > 0)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredParkList, (park, index) => {
            return (_openBlock(), _createElementBlock("li", {
              key: `park-${index}`
            }, [
              _createVNode(_component_router_link, {
                to: {
                            name: 'park',
                            params: { parkId: park.id },
                        }
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(park.name), 1)
                ]),
                _: 2
              }, 1032, ["to"])
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}