<template>
    <div class="header">
        <div class="header-title">
            <router-link
                to="/"
                class="logo"
            >
                <img
                    src="@/assets/qtimes.svg"
                    height="55"
                    alt="logo"
                />
            </router-link>
        </div>
        <span class="logo-header" v-if="$route.name==='home'">
            <span>Waiting times</span>
            <a
                class="support"
                href="https://8erbahnfreaks.de"
                >by 8erbahnFreaks.de</a
            >
        </span>
        <div class="actions">
            <input
                type="text"
                v-model="search"
                placeholder="search"
                class="search-field"
            />
        </div>
    </div>
    <router-view :search="search" />
    <footer class="footer">
        <a
            href="https://8erbahnfreaks.de/impressum"
            target="_blank"
            >Impressum</a
        >
        | Powered by
        <a
            href="https://queue-times.com"
            target="_blank"
            >queue-times.com</a
        >
    </footer>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
    data() {
        return {
            search: "",
        };
    },
    computed: {},
    watch: {
        $route(to, from) {
            if (to.name === "park") {
                this.search = "";
            }
        },
    },
});
</script>

<style lang="scss">
:root {
    --primary-color: #83c5be;
}
html {
    font-size: 16px;
}
body {
    margin: 0;
    padding: 0;
}
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    margin: 0;
    overflow: hidden;
    position: relative;
}

.bg-red {
    background-color: #f62a0a !important;
}
.bg-orange {
    background-color: #f08102 !important;
}
.bg-yellow {
    background-color: #f8b230 !important;
}

.logo {
    font-size: 1.75rem;
    font-weight: 600;
    color: var(--primary-color);
    text-transform: uppercase;
    flex: 0 0 auto;
}
.logo-header {
    padding: 0 0.5em 0 0;
    font-size: 0.9rem;
    height: 100%;
    flex-direction: column;
    flex: 1 0 auto;
    color: var(--primary-color);
    span {
        font-size: 1.1rem;
        display: block;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 0.05em;
    }
}

.header {
    border-bottom: 2px solid var(--primary-color);
    align-items: center;
    padding: 0.5em 0;
    overflow: hidden;

    display: flex;
    align-items: center;
    white-space: nowrap;

    .support {
        display: inline !important;
    }
    a,
    a:focus,
    a:active,
    a:hover {
        font-weight: bold;
        color: #2c3e50;
        text-decoration: none;
    }
    h1,
    h2,
    h3 {
        margin: 0;
        padding: 0;
        font-weight: normal;
    }
    h1 {
        font-size: 1.5rem;
        color: var(--primary-color);
    }
    h2 {
        font-size: 1rem;
        text-transform: uppercase;
    }
    .actions {
        margin-left: auto;
        flex: 1 1 auto;
        overflow: hidden;
    }
}
.header-title {
    padding: 0 0.5rem;
    img {
        line-height: 1;
        margin: 0;
        padding: 0;
        vertical-align: center;
    }
}
.footer {
    padding: 0.5rem;
    margin-top: 3rem;
    font-size: 0.65rem;
    a {
        text-decoration: none;
        color: #333;
    }
}

input {
    padding: 0.5em 0.5em;
    margin-top: 1rem;
    border-radius: 0;
    border: 1px solid #9b9b9b;
    -webkit-appearance: none;
}
.search-field {
    width: 92%;
    overflow: hidden;
    margin: 0 0.5rem 0 0;
}
</style>
